import React from 'react'
import Showdown from 'showdown'

class ProductYouTube extends React.Component {
  render() {
    const converter = new Showdown.Converter()    
    return (
        <>
            <h3 className={`font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-center pb-1`}>
                {this.props.data.title}
            </h3>

            {this.props.data.subtitle && 
                <p className="font-alt font-w-700 letter-spacing-1 text-center">
                    {this.props.data.subtitle}
                </p>
            }

            {this.props.data.intro && 
                <div
                    className="font-w-400 text-medium richtext divlink pt-2"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.data.intro) }}
                />
            }

            {(this.props.data.link != null && this.props.data.link != "") && (
                <div className="embed-responsive embed-responsive-16by9 mt-5">
                    <iframe
                        className="embed-responsive-item rounded border"
                        src={`${this.props.data.link}?feature=oembed&rel=0&modestbranding=1`}
                        allowfullscreen="allowfullscreen"
                    ></iframe>
                </div>
            )} 
        </>
    )
  }
}

export default ProductYouTube
